/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EngineLoadLimits } from './EngineLoadLimits';
import type { EngineSpeedLimits } from './EngineSpeedLimits';
import type { PedalLimits } from './PedalLimits';
import type { VehicleSetupIn } from './VehicleSetupIn';

/**
 * Vehicle setup
 */
export type VehicleSetup = {
  /**
   * Consumption norm
   */
  consumption_norm?: number;
  /**
   * GPS available (if not set, GPS is available by default)
   */
  gps?: boolean;
  /**
   * Number of fuel probes available, (if not set or zero, fuel not available)
   */
  fuel_probes?: number;
  /**
   * Nominal of each probe fuel tank (L)
   */
  fuel_volume?: {
    '1'?: number | null;
    '2'?: number | null;
    '3'?: number | null;
  } | null;
  /**
   * Nominal of CAN fuel tank, if not set, can_fuel not available (L)
   */
  can_fuel_volume?: number | null;
  /**
   * Has CAN flow meter
   */
  flow_meter?: boolean;
  /**
   * Uses odometer
   */
  uses_odometer?: boolean;
  /**
   * Fuel loss tolerance (L)
   */
  fuel_loss_tolerance?: number;
  /**
   * Refueling tolerance (L)
   */
  refueling_tolerance?: number;
  /**
   * Idle tolerance (sec)
   */
  idle_tolerance?: number;
  /**
   * Minimal trip duration (sec)
   */
  trip_duration?: number;
  /**
   * Voltage system, if not set = auto
   */
  voltage_system?: VehicleSetup.voltage_system;
  /**
   * Speed limit (km/h)
   */
  speed_limit?: number;
  in_1?: VehicleSetupIn;
  in_2?: VehicleSetupIn;
  in_3?: VehicleSetupIn;
  in_4?: VehicleSetupIn;
  in_5?: VehicleSetupIn;
  ext_in_1?: VehicleSetupIn;
  ext_in_2?: VehicleSetupIn;
  ext_in_3?: VehicleSetupIn;
  ext_in_4?: VehicleSetupIn;
  ext_in_5?: VehicleSetupIn;
  fms_pto?: {
    /**
     * Name of the PTO
     */
    name: string | null;
  };
  /**
   * Communication timeout (sec)
   */
  communication_timeout?: number | null;
  engine_speed_limits?: EngineSpeedLimits;
  engine_load_limits?: EngineLoadLimits;
  pedal_limits?: PedalLimits;
  /**
   * Path area width (meters)
   */
  path_area_width?: number;
  /**
   * Harsh cornering tolerance (G)
   */
  cornering_tolerance?: number;
  /**
   * Harsh braking tolerance (G)
   */
  braking_tolerance?: number;
  /**
   * Harsh acceleration tolerance (G)
   */
  acceleration_tolerance?: number;
};

export namespace VehicleSetup {
  /**
   * Voltage system, if not set = auto
   */
  export enum voltage_system {
    _12V = '12V',
    _24V = '24V',
    AUTO = 'auto',
  }
}
