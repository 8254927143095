import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { AuthService } from '../../states/auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router, private authService: AuthService) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const isLoggedIn = this.authService.isLoggedIn;
    return isLoggedIn;

    // TODO(Rasovsky): kontrola
    // load by default, interceptor will care about refreshing tokens
    /* const returnUrl = segments.map((s) => s.path).join('/');
    this.authService.signOut(returnUrl); */

    return false;
  }
}
