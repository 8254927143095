import { CommonModule } from '@angular/common';
import {
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from '@env/environment';
import { ApiModule } from './api/api.module';
import { UserIdleModule } from './components/user-idle/user-idle.module';
import { ServerErrorInterceptor } from './interceptors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ApiModule,
    UserIdleModule.forRoot({
      idle: environment.userIdleTimeout,
      timeout: 3600,
      ping: 120,
    }), //in seconds, 600 === 10 minutes
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'cs-CS' },
  ],
})
export class CoreModule {}
