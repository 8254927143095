import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, first, Observable, take, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { LoginService } from '../../../api';
import { AuthService } from '../../states/auth/services/auth.service';
import { LocalizedStrings } from '../configurations/LocalizedStrings';
import { TokenDto } from '../types/TokenDto';
import { LocalStorageItem, setLocalStorageItem } from '../utils';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private readonly loginService: LoginService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if ([401].includes(error.status)) {
          return this.handle401Error(request, next);
        } else if (error.status === 500) {
          return throwError(() => new Error(LocalizedStrings.ServerError));
        } else {
          return throwError(() => error);
        }
      }),
    );
  }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );

  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler) {
    //Call on refresh token failed, then sign out
    if (
      this.isRefreshing &&
      (request.url.endsWith('refresh_token') || request.url.endsWith('logout'))
    ) {
      this.authService.signOut(undefined, false);
      return throwError(() => new Error(LocalizedStrings.RefreshTokenError));
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.loginService.refreshToken().pipe(
        first(),
        switchMap((token) => {
          this.isRefreshing = false;
          if (token && 'access_token' in token) {
            return next.handle(this.addToken(request, token)).pipe(
              catchError(() => {
                //finally if in second try with refresh token also error, log out
                console.error(LocalizedStrings.RefreshTokenError);
                this.authService.signOut(this.router.url, false);
                return throwError(
                  () => new Error(LocalizedStrings.RefreshTokenError),
                );
              }),
            );
          } else {
            //finally if in second try with refresh token also error, log out
            console.error(LocalizedStrings.RefreshTokenError);
            this.authService.signOut(this.router.url, false);
            return throwError(
              () => new Error(LocalizedStrings.RefreshTokenError),
            );
          }
        }),
      );
    } else {
      return this.refreshTokenSubject.pipe(
        first(),
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        }),
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: TokenDto) {
    //Is needed also here because request is already created by the api library
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token.access_token}`,
      },
    });

    setLocalStorageItem(LocalStorageItem.Auth, token);

    return request;
  }
}
