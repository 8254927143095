/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EndDate } from '../models/EndDate';
import type { Error } from '../models/Error';
import type { FuelLossTolerance } from '../models/FuelLossTolerance';
import type { IdleTolerance } from '../models/IdleTolerance';
import type { RefuelingTolerance } from '../models/RefuelingTolerance';
import type { StartDate } from '../models/StartDate';
import type { Stats } from '../models/Stats';
import type { StatsEvent } from '../models/StatsEvent';
import type { UserId } from '../models/UserId';
import type { VehicleId } from '../models/VehicleId';
import type { WorkingHours } from '../models/WorkingHours';

import { OpenAPIOverridedClient } from '../core/OpenAPIOverridedClient';
import { request as __request } from '../core/request';

@Injectable()
export class StatsOverrideService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Working hours export
   * Working hours export
   * @param operatorId
   * @param startDate
   * @param groupBy
   * @param type
   * @param endDate
   * @returns binary OK
   * @returns Error Error
   * @throws ApiError
   */
  public workingHoursExport(
    operatorId: Array<UserId>,
    startDate: StartDate,
    groupBy: 'day' | 'week' | 'month',
    type: 'pdf',
    endDate?: EndDate,
  ): Observable<Blob | Error> {
    return __request(OpenAPIOverridedClient, this.http, {
      method: 'GET',
      url: '/stats/working_hours/grouped/export',
      query: {
        operator_id: operatorId,
        start_date: startDate,
        end_date: endDate,
        group_by: groupBy,
        type: type,
      },
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream',
      },
      errors: {
        400: `Validation error`,
        403: `Not enough rights`,
        404: `Invalid operator ID`,
      },
    });
  }

  /**
   * Export statistics
   * Export vehicle statistics
   * @param vehicleId
   * @param startDate
   * @param type
   * @param endDate
   * @returns binary OK
   * @returns Error Error
   * @throws ApiError
   */
  public statsExport(
    vehicleId: Array<VehicleId>,
    startDate: StartDate,
    type: 'xlsx' | 'csv',
    endDate?: EndDate,
  ): Observable<Blob | Error> {
    return __request(OpenAPIOverridedClient, this.http, {
      method: 'GET',
      url: '/stats/export',
      query: {
        vehicle_id: vehicleId,
        start_date: startDate,
        end_date: endDate,
        type: type,
      },
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream',
      },
      errors: {
        400: `Validation error`,
        403: `Not enough rights`,
        404: `Invalid vehicle IDs`,
        412: `Precondition error`,
      },
    });
  }
}
