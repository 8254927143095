import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoAuthGuard } from './core/guards';
import { CanDeactivateGuardService } from './core/guards/can-deactivate-guard.service';
import { NotFoundPage } from './core/layout/ui/not-found/not-found.page';
import { ROUTER_UTILS } from './core/utils/router.utils';

const APP_ROUTES: Routes = [
  {
    path: ROUTER_UTILS.config.auth.root,
    loadChildren: async () =>
      (await import('./states/auth/auth.module')).AuthModule,
    canLoad: [NoAuthGuard],
  },
  {
    path: ROUTER_UTILS.config.base.home,
    canLoad: [AuthGuard],
    redirectTo: ROUTER_UTILS.config.base.vehiclePark,
    pathMatch: 'full',
  },
  {
    path: ROUTER_UTILS.config.base.map,
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/map/map.module')).MapModule,
      },
    ],
  },
  {
    path: ROUTER_UTILS.config.base.routesBook,
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/routes-book/routes-book.module'))
            .RoutesBookModule,
      },
    ],
  },
  {
    path: ROUTER_UTILS.config.base.overview,
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/overview/overview.module')).OverviewModule,
      },
    ],
  },
  {
    path:
      ROUTER_UTILS.config.reports.basePath +
      '/' +
      ROUTER_UTILS.config.reports.canOverview,
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/can-overview/can-overview.module'))
            .CanOverviewModule,
      },
    ],
  },
  {
    path:
      ROUTER_UTILS.config.reports.basePath +
      '/' +
      ROUTER_UTILS.config.reports.drivingProgress,
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/driving-progress/driving-progress.module'))
            .DrivingProgressModule,
      },
    ],
  },
  {
    path:
      ROUTER_UTILS.config.reports.basePath +
      '/' +
      ROUTER_UTILS.config.reports.workingHours,
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/working-hours/working-hours.module'))
            .WorkingHoursModule,
      },
    ],
  },
  {
    path:
      ROUTER_UTILS.config.reports.basePath +
      '/' +
      ROUTER_UTILS.config.reports.refueling,
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/refueling/refueling.module')).RefuelingModule,
      },
    ],
  },
  {
    path:
      ROUTER_UTILS.config.reports.basePath +
      '/' +
      ROUTER_UTILS.config.reports.operatorsRegistry,
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (
            await import(
              './states/operators-registry/operators-registry.module'
            )
          ).OperatorsRegistryModule,
      },
    ],
  },
  {
    path:
      ROUTER_UTILS.config.reports.basePath +
      '/' +
      ROUTER_UTILS.config.reports.aetr,
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/aetr/aetr.module')).AetrModule,
      },
    ],
  },
  {
    path: ROUTER_UTILS.config.base.vehiclePark,
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/vehicle-park/vehicle-park.module'))
            .VehicleParkModule,
      },
    ],
  },
  {
    path: ROUTER_UTILS.config.base.vehicleDetail + '/:id',
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/vehicle-detail/vehicle-detail.module'))
            .VehicleDetailModule,
      },
    ],
  },
  {
    path: ROUTER_UTILS.config.reports.drivingStyle + '/:vehicleId/:eventId',
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/driving-style/driving-style.module'))
            .DrivingStyleModule,
      },
    ],
  },
  {
    path: ROUTER_UTILS.config.reports.routeDetail + '/:vehicleId/:id',
    canLoad: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: async () =>
          (await import('./states/route-detail/route-detail.module'))
            .RouteDetailModule,
      },
    ],
  },
  {
    path: '**',
    loadChildren: async () =>
      (await import('./core/layout/ui/not-found/not-found.module'))
        .NotFoundModule,
    component: NotFoundPage,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'corrected',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  providers: [CanDeactivateGuardService],
  exports: [RouterModule],
})
export class AppRoutingModule {}
