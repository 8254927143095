/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InputFn {
  PRIVATE = 'private',
  WORK = 'work',
  AREA = 'area',
  CRUISE_CONTROL = 'cruise_control',
}
