/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TripActivities = {
  id?: string;
  type?: TripActivities.type;
  value?: number;
  date?: string;
};

export namespace TripActivities {
  export enum type {
    HARSH_ACCELERATION = 'harsh_acceleration',
    HARSH_CORNERING = 'harsh_cornering',
    HARSH_BRAKING = 'harsh_braking',
  }
}
