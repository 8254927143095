import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { OperatorsService } from '../../../../api';
import { OperatorDto } from '../../types/OperatorDto';
import { OperatorRegistryDto } from '../../types/OperatorRegistryDto';
import {
  LocalStorageItem,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class CustomOperatorsService {
  constructor(private readonly operatorsService: OperatorsService) {}

  public async operatorsListAllCached(
    overrideCache = false,
  ): Promise<Array<OperatorDto>> {
    const allCache = getLocalStorageItem(LocalStorageItem.operatorList);

    if (allCache != null && !overrideCache) {
      return Promise.resolve(allCache) as Promise<Array<OperatorDto>>;
    }

    const result = await lastValueFrom(this.operatorsService.operatorList());

    if (result && !('result' in result)) {
      setLocalStorageItem(LocalStorageItem.operatorList, result);
      return result as Array<OperatorDto>;
    }

    return [];
  }

  public async operatorRegistryListAllCached(): Promise<OperatorRegistryDto> {
    const allCache = getLocalStorageItem<OperatorRegistryDto>(
      LocalStorageItem.operatorRegistry,
    );

    if (allCache != null) {
      return Promise.resolve(allCache);
    }

    const result = await lastValueFrom(
      this.operatorsService.operatorRegistry(),
    );

    if (result && !('result' in result)) {
      setLocalStorageItem(LocalStorageItem.operatorRegistry, result);
      return result as OperatorRegistryDto;
    }

    return {};
  }
}
