/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { RoleId } from '../models/RoleId';
import type { RoleIdentifier } from '../models/RoleIdentifier';
import type { UserId } from '../models/UserId';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RoleService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Create
   * Create role
   * @param requestBody
   * @returns any Role created
   * @throws ApiError
   */
  public roleCreate(requestBody: {
    parent_id: RoleId;
    identifier: RoleIdentifier;
  }): Observable<{
    id: RoleId;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/roles/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Assign role to the user
   * Assign role to the user
   * @param userId User ID
   * @param roleId Role ID
   * @returns any OK
   * @throws ApiError
   */
  public userRoleAssign(userId: string, roleId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/users/{user_id}/assign_role/{role_id}',
      path: {
        user_id: userId,
        role_id: roleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User/role doesn't exist`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete user's role
   * Delete user's role
   * @param userId User ID
   * @param roleId Role ID
   * @returns any OK
   * @throws ApiError
   */
  public userRoleDelete(userId: string, roleId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/users/{user_id}/assign_role/{role_id}',
      path: {
        user_id: userId,
        role_id: roleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User/role doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * List user's roles
   * List user's roles
   * @param userId User ID
   * @returns any Users available roles
   * @throws ApiError
   */
  public userRoleList(userId: string): Observable<
    Array<{
      id: UserId;
      identifier: RoleIdentifier;
      /**
       * Personal role
       */
      personal: boolean;
      /**
       * Array of sub roles
       */
      roles: Array<any>;
    }>
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/users/{user_id}/assign_role/',
      path: {
        user_id: userId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Assign right to the user
   * Assign right to the user
   * @param userId User ID
   * @param rightId Right ID
   * @returns any OK
   * @throws ApiError
   */
  public userRightAssign(userId: string, rightId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/users/{user_id}/assign_right/{right_id}',
      path: {
        user_id: userId,
        right_id: rightId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User/right doesn't exist`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete user's right
   * Delete user's right (only the one attached separately)
   * @param userId User ID
   * @param rightId Right ID
   * @returns any OK
   * @throws ApiError
   */
  public userRightDelete(userId: string, rightId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/users/{user_id}/assign_right/{right_id}',
      path: {
        user_id: userId,
        right_id: rightId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User/right doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * List user's rights
   * List user's rights
   * @param userId User ID
   * @returns any User rights
   * @throws ApiError
   */
  public userRightList(userId: string): Observable<
    Array<{
      /**
       * Role ID
       */
      id?: string;
      /**
       * Role identifier
       */
      identifier?: string;
    }>
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/users/{user_id}/assign_right/',
      path: {
        user_id: userId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Edit
   * Edit role
   * @param roleId Role ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public roleEdit(
    roleId: string,
    requestBody: {
      identifier: RoleIdentifier;
    },
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/roles/{role_id}',
      path: {
        role_id: roleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Role doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete
   * Delete role
   * @param roleId Role ID
   * @returns any Role deleted
   * @throws ApiError
   */
  public roleDelete(roleId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/roles/{role_id}',
      path: {
        role_id: roleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Role doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }
}
