/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { PhoneNumber } from '../models/PhoneNumber';
import type { Preferences } from '../models/Preferences';
import type { PreferencesNullable } from '../models/PreferencesNullable';
import type { UserEmail } from '../models/UserEmail';
import type { UserName } from '../models/UserName';
import type { UserPassword } from '../models/UserPassword';
import type { UserRights } from '../models/UserRights';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class MeService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Generate third-party token
   * Generate refresh token for app/third-party
   * @param requestBody
   * @returns any Receipt saved
   * @throws ApiError
   */
  public generateToken(requestBody: {
    /**
     * Token expiry, only up to 1 year
     */
    expires: string;
  }): Observable<{
    /**
     * Refresh token
     */
    refresh_token: string;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/me/tokens/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set my preferences
   * Set my preferences
   * @param requestBody
   * @returns any Saved
   * @throws ApiError
   */
  public preferencesSet(requestBody: Preferences): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/me/preferences',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get info about me
   * Get info about me
   * @returns any User info
   * @throws ApiError
   */
  public me(): Observable<
    UserName &
      PhoneNumber &
      UserEmail &
      UserRights &
      PreferencesNullable & {
        /**
         * First name
         */
        first_name: string | null;
        /**
         * Last name
         */
        last_name: string;
        /**
         * Phone number
         */
        phone_number: string | null;
        /**
         * Email address
         */
        email: string | null;
        rights: Array<string>;
        preferences: {
          /**
           * Show operators instead of vehicles on the main map
           */
          operators_on_map?: boolean;
          /**
           * ID of hidden vehicles on the main map
           */
          hidden_vehicles?: Array<string>;
          /**
           * Show operator "Last name | First name"
           */
          show_last_name_first?: boolean;
        } | null;
      }
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/me/',
      errors: {
        401: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Edit my details
   * Edit myself
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public editMyself(
    requestBody: UserName & PhoneNumber & UserPassword & UserEmail,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/me/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        500: `Server error`,
      },
    });
  }
}
