/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VehicleSetupIn = {
  /**
   * Name of the input
   */
  name: string | null;
  fn?: VehicleSetupIn.fn | null;
  /**
   * Display color of the input
   */
  color?: string | null;
};

export namespace VehicleSetupIn {
  export enum fn {
    PRIVATE = 'private',
    WORK = 'work',
  }
}
