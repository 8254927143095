/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { PhoneNumber } from '../models/PhoneNumber';
import type { RoleIdentifier } from '../models/RoleIdentifier';
import type { UserDisable } from '../models/UserDisable';
import type { UserEmail } from '../models/UserEmail';
import type { UserId } from '../models/UserId';
import type { UserIdPassword } from '../models/UserIdPassword';
import type { UserIdRegToken } from '../models/UserIdRegToken';
import type { UserName } from '../models/UserName';
import type { UserSinceUntil } from '../models/UserSinceUntil';
import type { UserUsername } from '../models/UserUsername';
import type { UserWithEmail } from '../models/UserWithEmail';
import type { UserWithUsername } from '../models/UserWithUsername';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class UserService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Create
   * Create user
   * @param requestBody
   * @returns any User created
   * @throws ApiError
   */
  public userCreate(
    requestBody: UserWithEmail | UserWithUsername,
  ): Observable<UserIdRegToken | UserIdPassword> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/users/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Group doesn't exist`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Assign role to the user
   * Assign role to the user
   * @param userId User ID
   * @param roleId Role ID
   * @returns any OK
   * @throws ApiError
   */
  public userRoleAssign(userId: string, roleId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/users/{user_id}/assign_role/{role_id}',
      path: {
        user_id: userId,
        role_id: roleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User/role doesn't exist`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete user's role
   * Delete user's role
   * @param userId User ID
   * @param roleId Role ID
   * @returns any OK
   * @throws ApiError
   */
  public userRoleDelete(userId: string, roleId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/users/{user_id}/assign_role/{role_id}',
      path: {
        user_id: userId,
        role_id: roleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User/role doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * List user's roles
   * List user's roles
   * @param userId User ID
   * @returns any Users available roles
   * @throws ApiError
   */
  public userRoleList(userId: string): Observable<
    Array<{
      id: UserId;
      identifier: RoleIdentifier;
      /**
       * Personal role
       */
      personal: boolean;
      /**
       * Array of sub roles
       */
      roles: Array<any>;
    }>
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/users/{user_id}/assign_role/',
      path: {
        user_id: userId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Assign right to the user
   * Assign right to the user
   * @param userId User ID
   * @param rightId Right ID
   * @returns any OK
   * @throws ApiError
   */
  public userRightAssign(userId: string, rightId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/users/{user_id}/assign_right/{right_id}',
      path: {
        user_id: userId,
        right_id: rightId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User/right doesn't exist`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete user's right
   * Delete user's right (only the one attached separately)
   * @param userId User ID
   * @param rightId Right ID
   * @returns any OK
   * @throws ApiError
   */
  public userRightDelete(userId: string, rightId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/users/{user_id}/assign_right/{right_id}',
      path: {
        user_id: userId,
        right_id: rightId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User/right doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * List user's rights
   * List user's rights
   * @param userId User ID
   * @returns any User rights
   * @throws ApiError
   */
  public userRightList(userId: string): Observable<
    Array<{
      /**
       * Role ID
       */
      id?: string;
      /**
       * Role identifier
       */
      identifier?: string;
    }>
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/users/{user_id}/assign_right/',
      path: {
        user_id: userId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get user info
   * Get user info
   * @param userId User ID
   * @returns any User info
   * @throws ApiError
   */
  public userList(userId: string): Observable<
    {
      id?: UserId;
    } & UserName &
      UserUsername &
      PhoneNumber &
      UserEmail &
      UserSinceUntil &
      UserDisable & {
        id: UserId;
        /**
         * First name
         */
        first_name: string | null;
        /**
         * Last name
         */
        last_name: string;
        /**
         * Username
         */
        username: string;
        /**
         * Phone number
         */
        phone_number: string | null;
        /**
         * Email address
         */
        email: string | null;
        /**
         * Since when the user becomes valid
         */
        since: string;
        /**
         * Until when will the user be valid
         */
        until: string | null;
        /**
         * Disable/enable user
         */
        disabled: boolean;
        /**
         * Messages to show when someone tries to login
         */
        disable_reason: string | null;
      }
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/users/{user_id}/',
      path: {
        user_id: userId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Edit user
   * Edit user
   * @param userId User ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public userEdit(
    userId: string,
    requestBody: UserName &
      PhoneNumber &
      UserEmail &
      UserSinceUntil &
      UserDisable,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/users/{user_id}/',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete
   * Delete user
   * @param userId User ID
   * @param permanent
   * @returns any User deleted
   * @throws ApiError
   */
  public userDelete(
    userId: string,
    permanent: boolean = false,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/users/{user_id}/',
      path: {
        user_id: userId,
      },
      query: {
        permanent: permanent,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User doesn't exist`,
        500: `Server error`,
      },
    });
  }
}
