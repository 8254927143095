/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EventCommon } from './EventCommon';
import type { EventData } from './EventData';
import type { EventFlags } from './EventFlags';
import type { EventIdentities } from './EventIdentities';
import type { EventLocation } from './EventLocation';
import type { StatsBase } from './StatsBase';
import type { StatsFuelConsumption } from './StatsFuelConsumption';
import type { StatsInputs } from './StatsInputs';
import type { StatsOdometer } from './StatsOdometer';
import type { Vehicle } from './Vehicle';

export type TripBase = EventCommon &
  EventData &
  EventLocation &
  EventIdentities &
  Vehicle &
  EventFlags & {
    stats: StatsBase &
      StatsOdometer &
      StatsInputs & {
        fuel_consumption: {
          km: StatsFuelConsumption;
          hour: StatsFuelConsumption;
        };
      };
    class_type: TripBase.class_type;
    /**
     * Duration (sec)
     */
    duration: number;
  };

export namespace TripBase {
  export enum class_type {
    TRIP = 'trip',
  }
}
