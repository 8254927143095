/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class DefaultService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Check if API is running
   * Check if API is running
   * @returns any OK
   * @throws ApiError
   */
  public status(): Observable<{
    status: string;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/status',
      errors: {
        500: `Server error`,
      },
    });
  }

  /**
   * Get definition
   * Get OpenAPI definition
   * @returns any OpenAPI definition
   * @throws ApiError
   */
  public definition(): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/definition',
    });
  }
}
