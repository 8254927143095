import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ROUTER_UTILS } from '../../../utils/router.utils';
import { AuthService } from '../../../../states/auth/services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
  public showMemu = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((value) => {
        if (
          this.router.url.startsWith(
            '/' + ROUTER_UTILS.config.reports.drivingStyle,
          ) ||
          this.router.url.startsWith(
            '/' + ROUTER_UTILS.config.reports.routeDetail,
          )
        ) {
          this.showMemu = false;
        } else {
          this.showMemu = true;
        }
      });
  }
}
