<div class="container flex items-center justify-center h-full mt-12">
  <div class="not-found-image-wrapper">
    <img src="assets/images/404.png" />
    <div class="not-found-text">
      <h1 class="not-found-text-header" i18n>Chyba 404</h1>
      <p class="not-found-text-desc">
        <span>Tato stránka nebyla nalezena</span><br /><br />
        <a [routerLink]="'/' + path.vehiclePark" class="btn btn-primary" i18n>
          Vozový park
        </a>
      </p>
    </div>
  </div>
</div>
