/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { GroupId } from '../models/GroupId';
import type { GroupName } from '../models/GroupName';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class UserAssignService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Assign vehicle to the user
   * Assign vehicle to the user, please note: assigning vehicles to the group and then group to a user is preffered way
   * @param userId User ID
   * @param vehicleId Vehicle ID
   * @returns any OK
   * @throws ApiError
   */
  public userVehicleAssign(userId: string, vehicleId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/users/{user_id}/assign_vehicle/{vehicle_id}',
      path: {
        user_id: userId,
        vehicle_id: vehicleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle/user ID`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete user's assigned vehicle
   * Delete user's assigned vehicle
   * @param userId User ID
   * @param vehicleId Vehicle ID
   * @returns any OK
   * @throws ApiError
   */
  public userVehicleDelete(userId: string, vehicleId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/users/{user_id}/assign_vehicle/{vehicle_id}',
      path: {
        user_id: userId,
        vehicle_id: vehicleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle/user`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Assign group to the user
   * Assign group to the user
   * @param userId User ID
   * @param groupId
   * @returns any OK
   * @throws ApiError
   */
  public userAssignGroup(userId: string, groupId: GroupId): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/users/{user_id}/assign_group/{group_id}',
      path: {
        user_id: userId,
        group_id: groupId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User/group doesn't exist`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete assigned group
   * Delete assigned group
   * @param userId User ID
   * @param groupId
   * @returns any OK
   * @throws ApiError
   */
  public userAssignGroupDelete(
    userId: string,
    groupId: GroupId,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/users/{user_id}/assign_group/{group_id}',
      path: {
        user_id: userId,
        group_id: groupId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `User/group doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * List user's groups
   * List user's groups
   * @param userId User ID
   * @returns any Available groups
   * @throws ApiError
   */
  public userGroupList(userId: string): Observable<
    Array<{
      id: GroupId;
      name: GroupName;
      /**
       * Array of subgroups
       */
      groups: Array<any>;
    }>
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/users/{user_id}/assign_group/',
      path: {
        user_id: userId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        500: `Server error`,
      },
    });
  }
}
