import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LoginService, MeService, OpenAPI } from '../api';
import { LocalizedStrings } from './core/configurations/LocalizedStrings';
import { CustomGroupService } from './core/services/custom-group/custom-group.service';
import { CustomOperatorsService } from './core/services/custom-operators/custom-operators.service';
import { CustomVehicleService } from './core/services/custom-vehicle/custom-vehicle.service';
import { GoogleMapsService } from './core/services/google-maps/google-maps.service';
import { SeoService } from './core/services/seo';
import { ThemeService } from './core/services/theme';
import { TokenDto } from './core/types/TokenDto';
import {
  LocalStorageItem,
  getLocalStorageItem,
  setLocalStorageItem,
} from './core/utils';
import { ROUTER_UTILS } from './core/utils/router.utils';
import { AuthService } from './states/auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn$!: Observable<boolean>;
  subscriptionSetEventTitle: Subscription | null = null;
  subscriptionRouteChanged: Subscription | null = null;

  public intervalReloadCache: ReturnType<typeof setInterval> | null = null;

  constructor(
    private seoService: SeoService,
    private themeService: ThemeService,
    private authService: AuthService,
    private meService: MeService,
    private customGroupService: CustomGroupService,
    private customVehicleService: CustomVehicleService,
    private customOperatorsService: CustomOperatorsService,
    private googleMapsService: GoogleMapsService,
    private readonly loginService: LoginService,
    private router: Router,
    private titleService: Title,
    private _renderer: Renderer2,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoggedIn$ = this.authService.isLoggedIn$;
    this.runGlobalServices();
    OpenAPI.TOKEN = this.getToken;
    moment.locale('cs'); //setup localization dates

    //Custom title
    this.subscriptionSetEventTitle = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route?.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route?.snapshot.data['title'];
          }
          return routeTitle;
        }),
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`${title} | ` + $localize`Data z auta`);
        } else {
          this.titleService.setTitle($localize`Data z auta`);
        }
      });

    this.subscriptionRouteChanged = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        //Remove left filter default class
        this._renderer.removeClass(document.body, 'left-filter-visible');
      });

    if (!this.authService.isLoggedIn) {
      try {
        const newToken = await lastValueFrom(this.loginService.refreshToken());

        setLocalStorageItem(LocalStorageItem.Auth, newToken);
      } catch (e) {
        console.error(LocalizedStrings.RefreshTokenError);
        this.authService.signOut(
          this.router.url.startsWith(`/${ROUTER_UTILS.config.auth.root}`)
            ? undefined
            : this.router.url,
          false,
        );
        return;
      }
    }

    await this.reloadMeData();
    await this.customGroupService.groupListCached(true);
    await this.customVehicleService.vehicleListAllCached(true);
    await this.customOperatorsService.operatorsListAllCached(true);

    this.intervalReloadCache = setInterval(async () => {
      await this.reloadMeData();
      await this.customGroupService.groupListCached(true);
      await this.customVehicleService.vehicleListAllCached(true);
      await this.customOperatorsService.operatorsListAllCached(true);
    }, 3600000); //1hour interval
  }

  private async reloadMeData() {
    const meResponse = await lastValueFrom(this.meService.me());
    setLocalStorageItem(LocalStorageItem.meInformation, meResponse);
  }

  ngOnDestroy(): void {
    if (this.subscriptionSetEventTitle) {
      this.subscriptionSetEventTitle?.unsubscribe();
    }
    if (this.subscriptionRouteChanged) {
      this.subscriptionRouteChanged?.unsubscribe();
    }
    if (this.intervalReloadCache) {
      clearInterval(this.intervalReloadCache);
    }
  }

  private runGlobalServices(): void {
    this.seoService.init();
    this.themeService.init();
  }

  public async signOut(): Promise<void> {
    await this.authService.signOut(undefined, false);
  }

  public getToken = async () => {
    const token = getLocalStorageItem<TokenDto>(LocalStorageItem.Auth);

    // TODO(Rasovsky): kontrola
    // this way interceptor tries to refresh token
    return token?.access_token || '';
  };
}
