/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { GroupId } from '../models/GroupId';
import type { GroupName } from '../models/GroupName';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class GroupsService {
  constructor(public readonly http: HttpClient) {}

  /**
   * List
   * List available groups
   * @returns any Available groups
   * @throws ApiError
   */
  public groupList(): Observable<
    Array<{
      id: GroupId;
      name: GroupName;
      /**
       * Array of subgroups
       */
      groups: Array<any>;
    }>
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/groups/',
      errors: {
        401: `Unauthorized`,
        403: `Not enough rights`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create
   * Create group
   * @param requestBody
   * @returns any Group created
   * @throws ApiError
   */
  public groupCreate(requestBody: {
    /**
     * Parent group ID (if not specified, it will be highest possible)
     */
    parent_id?: string;
    /**
     * Group name
     */
    name: string;
  }): Observable<{
    id: GroupId;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/groups/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete
   * Delete group
   * @param groupId Group ID
   * @returns any Group deleted
   * @throws ApiError
   */
  public groupDelete(groupId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/groups/{group_id}',
      path: {
        group_id: groupId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Group doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }
}
