import { NgModule } from '@angular/core';
import {
  AetrService,
  DefaultService,
  EventsService,
  GroupsService,
  LoginService,
  MeService,
  ObuService,
  OperatorsService,
  RefuelingService,
  RoleService,
  StatsService,
  TripsService,
  UserAssignService,
  UserRegistrationService,
  UserService,
  VehicleService,
} from '../../../api';
import { StatsOverrideService } from '../../../apiOverride/services/StatsService';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    AetrService,
    DefaultService,
    EventsService,
    GroupsService,
    LoginService,
    MeService,
    ObuService,
    OperatorsService,
    RoleService,
    RefuelingService,
    StatsService,
    StatsOverrideService,
    TripsService,
    UserAssignService,
    UserRegistrationService,
    UserService,
    VehicleService,
  ],
})
export class ApiModule {}
