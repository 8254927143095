import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header.component';
import { SvgIconModule } from '../../../../common/components/svg-icon/svg-icon.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, RouterModule, SvgIconModule, CollapseModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
