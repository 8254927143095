import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  private apiLoaded = false;
  private isApiLoading: Promise<void> | undefined = undefined;

  constructor(public readonly httpClient: HttpClient) {}

  public async isApiLoaded(): Promise<boolean> {
    if (this.apiLoaded) {
      return true;
    } else {
      if (this.isApiLoading === undefined) {
        this.isApiLoading = new Promise<void>(async (resolve, reject) => {
          const result = (await this.httpClient
            .jsonp(
              'https://maps.googleapis.com/maps/api/js?key=' +
                environment.googleAPIKey,
              'callback',
            )
            .pipe(
              map(() => true),
              catchError(() => of(false)),
            )
            .toPromise()) as boolean;

          this.apiLoaded = result;
          resolve();
        });
        await this.isApiLoading;
      } else {
        await this.isApiLoading;
        return await this.isApiLoaded();
      }

      return this.apiLoaded;
    }
  }
}
