import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterModule } from './ui/footer/footer.module';
import { HeaderModule } from './ui/header/header.module';
import { LayoutModule } from './ui/layout/layout.module';
import { NotFoundModule } from './ui/not-found/not-found.module';

@NgModule({
  imports: [
    CommonModule,
    FooterModule,
    HeaderModule,
    LayoutModule,
    NotFoundModule,
  ],
  exports: [
    RouterModule,
    FooterModule,
    HeaderModule,
    LayoutModule,
    NotFoundModule,
  ],
})
export class AppLayoutModule {}
