/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AetrPeriod } from '../models/AetrPeriod';
import type { PhoneNumber } from '../models/PhoneNumber';
import type { UserAssignmentId } from '../models/UserAssignmentId';
import type { UserEmail } from '../models/UserEmail';
import type { UserId } from '../models/UserId';
import type { UserIdentity } from '../models/UserIdentity';
import type { UserName } from '../models/UserName';
import type { UserSinceUntil } from '../models/UserSinceUntil';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class OperatorsService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Operators registry
   * Operators registry
   * @returns any OK
   * @throws ApiError
   */
  public operatorRegistry(): Observable<{
    assigned?: Array<
      {
        id?: UserId;
        identity?: Array<
          UserIdentity &
            UserSinceUntil &
            UserAssignmentId & {
              /**
               * Since when the user becomes valid
               */
              since: string;
              /**
               * Until when will the user be valid
               */
              until: string | null;
            }
        >;
      } & UserName &
        PhoneNumber &
        UserEmail & {
          /**
           * Operator info can be edited
           */
          editable?: boolean;
        } & {
          id: UserId;
          identity: Array<
            UserIdentity &
              UserSinceUntil &
              UserAssignmentId & {
                /**
                 * Since when the user becomes valid
                 */
                since: string;
                /**
                 * Until when will the user be valid
                 */
                until: string | null;
              }
          >;
          /**
           * First name
           */
          first_name: string | null;
          /**
           * Last name
           */
          last_name: string;
          /**
           * Phone number
           */
          phone_number: string | null;
          /**
           * Email address
           */
          email: string | null;
          /**
           * Operator info can be edited
           */
          editable: boolean;
        }
    >;
    unassigned?: Array<UserIdentity>;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/operators/registry',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        500: `Server error`,
      },
    });
  }

  /**
   * List operators
   * List assigned operators
   * @returns any OK
   * @throws ApiError
   */
  public operatorList(): Observable<
    Array<
      {
        id?: UserId;
      } & UserName & {
          id: UserId;
          /**
           * First name
           */
          first_name: string | null;
          /**
           * Last name
           */
          last_name: string;
        }
    >
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/operators/',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        500: `Server error`,
      },
    });
  }

  /**
   * Add operator
   * Add operator
   * @param requestBody
   * @returns any Operator created
   * @throws ApiError
   */
  public operatorAdd(
    requestBody: UserName &
      PhoneNumber &
      UserEmail & {
        /**
         * Last name
         */
        last_name: string;
      },
  ): Observable<{
    operator_id?: UserId;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/operators/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        500: `Server error`,
      },
    });
  }

  /**
   * Edit identity assignment
   * Edit identity assignment
   * @param assignmentId Identity assignment ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public operatorIdentityEdit(
    assignmentId: string,
    requestBody:
      | {
          /**
           * Since when is the identity assigned
           */
          since: string;
        }
      | {
          /**
           * Until when is the identity assigned
           */
          until: string | null;
        },
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/operators/identity/assignment/{assignment_id}',
      path: {
        assignment_id: assignmentId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid assignment ID`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete identity assignment
   * Delete identity assignment
   * @param assignmentId Identity assignment ID
   * @returns any Deleted
   * @throws ApiError
   */
  public operatorIdentityDelete(assignmentId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/operators/identity/assignment/{assignment_id}',
      path: {
        assignment_id: assignmentId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid identity`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create operator and assign identity
   * Create operator and assign identity
   * @param identityId Identity ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public operatorNewIdentityAssign(
    identityId: string,
    requestBody: UserName &
      PhoneNumber &
      UserEmail &
      (
        | {
            /**
             * Since when is the identity assigned
             */
            since: string;
          }
        | {
            /**
             * Until when is the identity assigned
             */
            until: string | null;
          }
      ) & {
        /**
         * Last name
         */
        last_name: string;
      },
  ): Observable<
    {
      operator_id?: UserId;
    } & UserAssignmentId & {
        operator_id: UserId;
      }
  > {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/operators/identity/{identity_id}',
      path: {
        identity_id: identityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid identity`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Identity assign
   * Assign identity to operator
   * @param operatorId Operator ID
   * @param identityId Identity ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public operatorIdentityAssign(
    operatorId: string,
    identityId: string,
    requestBody:
      | {
          /**
           * Since when is the identity assigned
           */
          since: string;
        }
      | {
          /**
           * Until when is the identity assigned
           */
          until: string | null;
        },
  ): Observable<UserAssignmentId> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/operators/{operator_id}/identity/{identity_id}',
      path: {
        operator_id: operatorId,
        identity_id: identityId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid user/identity`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get operator AETR status
   * Get operator AETR
   * @param operatorId Operator ID
   * @returns any OK
   * @throws ApiError
   */
  public operatorAetr(operatorId: string): Observable<{
    /**
     * Current rest time (sec)
     */
    rest_in_progress?: number | null;
    current_driving?: AetrPeriod;
    daily_period?: AetrPeriod;
    weekly_period?: AetrPeriod & {
      /**
       * Available drive stretch (days)
       */
      available_drive_stretch: number | null;
      /**
       * Available rest cut (days)
       */
      available_rest_cut: number | null;
    };
  }> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/operators/{operator_id}/aetr_status',
      path: {
        operator_id: operatorId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        500: `Server error`,
      },
    });
  }

  /**
   * Edit operator
   * Edit operator
   * @param operatorId Operator ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public operatorEdit(
    operatorId: string,
    requestBody: UserName | PhoneNumber | UserEmail,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/operators/{operator_id}/',
      path: {
        operator_id: operatorId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid operator ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete operator
   * Delete operator
   * @param operatorId Operator ID
   * @returns any Deleted
   * @throws ApiError
   */
  public operatorDelete(operatorId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/operators/{operator_id}/',
      path: {
        operator_id: operatorId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid operator ID`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }
}
