/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InputFn } from './InputFn';

export type InputConfig = {
  key: InputConfig.key;
  /**
   * Name of the input
   */
  name: string | null;
  fn: InputFn;
  colors: {
    primary: string;
    secondary: string;
  };
};

export namespace InputConfig {
  export enum key {
    IN_1 = 'in_1',
    IN_2 = 'in_2',
    IN_3 = 'in_3',
    IN_4 = 'in_4',
    IN_5 = 'in_5',
    EXT_IN_1 = 'ext_in_1',
    EXT_IN_2 = 'ext_in_2',
    EXT_IN_3 = 'ext_in_3',
    EXT_IN_4 = 'ext_in_4',
    EXT_IN_5 = 'ext_in_5',
    FMS_PTO = 'fms_pto',
    CRUISE_CONTROL = 'cruise_control',
  }
}
