import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GroupsService } from '../../../../api';
import { GroupDto } from '../../types/GroupDto';
import {
  LocalStorageItem,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class CustomGroupService {
  constructor(private readonly groupService: GroupsService) {}

  public async groupListCached(
    overrideCache = false,
  ): Promise<Array<GroupDto>> {
    const groupListCache = getLocalStorageItem<Array<GroupDto>>(
      LocalStorageItem.groupList,
    );

    if (groupListCache != null && !overrideCache) {
      return Promise.resolve(groupListCache);
    }

    try {
      const result = await lastValueFrom(this.groupService.groupList());

      setLocalStorageItem(LocalStorageItem.groupList, result);
      return result as Array<GroupDto>;
    } catch (e) {
      console.error(e);
    }

    return [];
  }
}
