/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EventBase } from './EventBase';
import type { EventData } from './EventData';
import type { EventLocation } from './EventLocation';
import type { Vehicle } from './Vehicle';

export type NoTrip = EventBase &
  Vehicle &
  EventData &
  EventLocation & {
    class_type?: NoTrip.class_type;
  } & {
    class_type: NoTrip.class_type;
  };

export namespace NoTrip {
  export enum class_type {
    NO_TRIP = 'no_trip',
  }
}
