/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AetrPeriod } from '../models/AetrPeriod';
import type { EndDate } from '../models/EndDate';
import type { StartDate } from '../models/StartDate';
import type { UserId } from '../models/UserId';
import type { VehicleData } from '../models/VehicleData';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AetrService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Daily operator AETR
   * Get daily operator AETR ⚠️ ! mock ! ⚠️
   * @param operatorId
   * @param startDate
   * @param endDate
   * @returns any OK
   * @throws ApiError
   */
  public aetrDaily(
    operatorId: UserId,
    startDate: StartDate,
    endDate?: EndDate,
  ): Observable<
    Array<{
      /**
       * Start date
       */
      start_date: string;
      /**
       * End date
       */
      end_date: string;
      /**
       * Operator ID
       */
      operator_id: string;
      start_data: VehicleData | null;
      end_data: VehicleData | null;
      aetr_stats: {
        /**
         * seconds
         */
        drive: number;
        /**
         * seconds
         */
        work: number;
        /**
         * seconds
         */
        available: number;
        /**
         * seconds
         */
        rest: number;
        /**
         * seconds
         */
        weekly_rest: number;
        /**
         * meters
         */
        traveled_distance: number;
      };
      aetr_events: Array<{
        type: 'drive' | 'work' | 'available' | 'rest' | 'weekly_rest';
        /**
         * Start date
         */
        start_at: string;
        /**
         * End date
         */
        end_at: string;
        /**
         * seconds
         */
        duration: number;
      }>;
    }>
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/aetr/daily',
      query: {
        operator_id: operatorId,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Operator ID doesn't exist`,
        500: `Server error`,
      },
    });
  }

  /**
   * Weekly operator AETR
   * Get Weekly operator AETR ⚠️ ! mock ! ⚠️
   * @param operatorId
   * @param startDate
   * @param endDate
   * @returns any OK
   * @throws ApiError
   */
  public aetrWeekly(
    operatorId: UserId,
    startDate: StartDate,
    endDate?: EndDate,
  ): Observable<
    Array<{
      /**
       * Start date
       */
      start_date: string;
      /**
       * End date
       */
      end_date: string;
      operator_id: UserId;
      start_data: VehicleData | null;
      end_data: VehicleData | null;
      aetr_stats: {
        /**
         * seconds
         */
        drive: number;
        /**
         * seconds
         */
        work: number;
        /**
         * seconds
         */
        available: number;
        /**
         * seconds
         */
        rest: number;
        /**
         * seconds
         */
        weekly_rest: number;
        /**
         * meters
         */
        traveled_distance: number;
      };
      aetr_events: Array<{
        type: 'drive' | 'work' | 'available' | 'rest' | 'weekly_rest';
        /**
         * Start date
         */
        start_at: string;
        /**
         * End date
         */
        end_at: string;
        /**
         * seconds
         */
        duration: number;
      }>;
    }>
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/aetr/',
      query: {
        operator_id: operatorId,
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Operator ID doesn't exist`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get operator AETR status
   * Get operator AETR
   * @param operatorId Operator ID
   * @returns any OK
   * @throws ApiError
   */
  public operatorAetr(operatorId: string): Observable<{
    /**
     * Current rest time (sec)
     */
    rest_in_progress?: number | null;
    current_driving?: AetrPeriod;
    daily_period?: AetrPeriod;
    weekly_period?: AetrPeriod & {
      /**
       * Available drive stretch (days)
       */
      available_drive_stretch: number | null;
      /**
       * Available rest cut (days)
       */
      available_rest_cut: number | null;
    };
  }> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/operators/{operator_id}/aetr_status',
      path: {
        operator_id: operatorId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        500: `Server error`,
      },
    });
  }
}
