import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { VehicleService } from '../../../../api';
import { VehicleDto } from '../../types/VehicleDto';
import {
  LocalStorageItem,
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class CustomVehicleService {
  constructor(private readonly vehicleService: VehicleService) {}

  public async vehicleListAllCached(
    overrideCache = false,
  ): Promise<Array<VehicleDto>> {
    const vehicleListAllCache = getLocalStorageItem(
      LocalStorageItem.VehicleListAll,
    );

    if (vehicleListAllCache != null && !overrideCache) {
      return Promise.resolve(vehicleListAllCache) as Promise<Array<VehicleDto>>;
    }

    const result = await lastValueFrom(this.vehicleService.vehicleListAll());
    if (!('result' in result)) {
      setLocalStorageItem(LocalStorageItem.VehicleListAll, result);
      return result as Array<VehicleDto>;
    }

    return [];
  }

  public async vehiclesGetAndCache(): Promise<Array<VehicleDto>> {
    const result = await lastValueFrom(this.vehicleService.vehicleListAll());

    if (!('result' in result)) {
      setLocalStorageItem(LocalStorageItem.VehicleListAll, result);
      return result as Array<VehicleDto>;
    }

    return [];
  }
}
