/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EndDate } from '../models/EndDate';
import type { EventArea } from '../models/EventArea';
import type { EventCommon } from '../models/EventCommon';
import type { EventOutput } from '../models/EventOutput';
import type { StartDate } from '../models/StartDate';
import type { UserId } from '../models/UserId';
import type { VehicleData } from '../models/VehicleData';
import type { VehicleId } from '../models/VehicleId';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class EventsService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Get last entries
   * Get last entries
   * @param vehicleId
   * @param data Data entries
   * @param interval Last interval (sec)
   * @param interleaved Whether the previous data should be interleaved with the next ones
   * @returns any OK
   * @throws ApiError
   */
  public lastEntries(
    vehicleId: VehicleId,
    data: Array<string>,
    interval: number,
    interleaved: boolean = false,
  ): Observable<
    Array<{
      date: string;
      data: VehicleData;
    }>
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/events/last_entries',
      query: {
        vehicle_id: vehicleId,
        data: data,
        interleaved: interleaved,
        interval: interval,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get events
   * Get events
   * ```
   * request.start-------------------------------request.end
   * event.start----------------event.end      event.start-----------------event.end
   * ```
   *
   * @param startDate
   * @param type
   * @param vehicleId
   * @param operatorId
   * @param endDate
   * @returns EventOutput OK
   * @throws ApiError
   */
  public events(
    startDate: StartDate,
    type: Array<string>,
    vehicleId?: VehicleId,
    operatorId?: UserId,
    endDate?: EndDate,
  ): Observable<Array<EventOutput>> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/events/',
      query: {
        vehicle_id: vehicleId,
        operator_id: operatorId,
        start_date: startDate,
        end_date: endDate,
        type: type,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Vehicle/operator ID doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Generate polygon
   * Generate polygon from coordinates
   * @param eventId Event ID
   * @returns any OK
   * @throws ApiError
   */
  public polygon(eventId: string): Observable<
    | (EventCommon &
        EventArea & {
          polygon: Array<
            Array<
              Array<{
                lat: number;
                lng: number;
              }>
            >
          >;
        })
    | null
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/events/{event_id}/polygon',
      path: {
        event_id: eventId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Event ID doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set event note
   * Set event note
   * @param eventId Event ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public eventNote(
    eventId: string,
    requestBody: {
      /**
       * Note
       */
      note: string | null;
    },
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/events/{event_id}/note',
      path: {
        event_id: eventId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Event ID doesn't exist`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get entries
   * Get entries
   * @param eventId Event ID
   * @param data Specify data
   * @param interleaved Whether the previous data should be interleaved with the next ones
   * @returns any OK
   * @throws ApiError
   */
  public entries(
    eventId: string,
    data: Array<string>,
    interleaved: boolean = false,
  ): Observable<
    Array<{
      date: string;
      data: VehicleData;
    }>
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/events/{event_id}/entries',
      path: {
        event_id: eventId,
      },
      query: {
        data: data,
        interleaved: interleaved,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Event ID doesn't exist`,
        500: `Server error`,
      },
    });
  }
}
