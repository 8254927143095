/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { UserId } from '../models/UserId';
import type { UserName } from '../models/UserName';
import type { UserPassword } from '../models/UserPassword';
import type { UserRegToken } from '../models/UserRegToken';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class UserRegistrationService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Check register token
   * Check user's register token
   * @param regToken
   * @returns any User created
   * @throws ApiError
   */
  public userRegisterToken(regToken: UserRegToken): Observable<{
    id: UserId;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/users/register/{reg_token}',
      path: {
        reg_token: regToken,
      },
      errors: {
        400: `Validation error`,
        404: `Invalid token`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set new user password
   * Set new user password
   * @param regToken
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public userSetPassword(
    regToken: UserRegToken,
    requestBody: UserName &
      UserPassword & {
        /**
         * Last name
         */
        last_name: string;
        /**
         * Password
         */
        password: string;
      },
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/users/register/{reg_token}',
      path: {
        reg_token: regToken,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        404: `Invalid user`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }
}
