/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class LoginService {
  constructor(public readonly http: HttpClient) {}

  /**
   * Refresh token
   * Refresh token
   * @param requestBody
   * @returns any Token refreshed
   * @throws ApiError
   */
  public refreshToken(requestBody?: {
    /**
     * Token when using long lived, otherwise it needs to be in cookie
     */
    refresh_token?: string;
  }): Observable<{
    access_token: string;
    /**
     * Token expiry date
     */
    expires: string;
    /**
     * User ID
     */
    user_id: string;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/refresh_token',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        429: `Error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Logout
   * Logout
   * @param requestBody
   * @returns any Successfully logged out
   * @throws ApiError
   */
  public logout(requestBody?: {
    /**
     * Force to logout all devices
     */
    all?: boolean;
  }): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/logout',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized`,
        500: `Server error`,
      },
    });
  }

  /**
   * Login
   * Perform login
   * @param requestBody
   * @returns any Successfully logged in
   * @throws ApiError
   */
  public login(requestBody: {
    /**
     * Username/email
     */
    username: string;
    /**
     * Password
     */
    password: string;
  }): Observable<{
    access_token: string;
    /**
     * Token expiry date
     */
    expires: string;
    /**
     * User ID
     */
    user_id: string;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        404: `Wrong username or password`,
        500: `Server error`,
      },
    });
  }
}
