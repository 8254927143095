/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { AddressName } from '../models/AddressName';
import type { AssignmentId } from '../models/AssignmentId';
import type { Date } from '../models/Date';
import type { DtcoActivity } from '../models/DtcoActivity';
import type { GroupId } from '../models/GroupId';
import type { GroupIdNull } from '../models/GroupIdNull';
import type { GroupIdObj } from '../models/GroupIdObj';
import type { IntervalId } from '../models/IntervalId';
import type { IntervalName } from '../models/IntervalName';
import type { ObuSince } from '../models/ObuSince';
import type { ObuSinceUntil } from '../models/ObuSinceUntil';
import type { ObuUntil } from '../models/ObuUntil';
import type { SetOdometer } from '../models/SetOdometer';
import type { Triggers } from '../models/Triggers';
import type { TriggersNullable } from '../models/TriggersNullable';
import type { UserAssignmentId } from '../models/UserAssignmentId';
import type { UserIdentity } from '../models/UserIdentity';
import type { VehicleAttr } from '../models/VehicleAttr';
import type { VehicleIcon } from '../models/VehicleIcon';
import type { VehicleId } from '../models/VehicleId';
import type { VehicleInfo } from '../models/VehicleInfo';
import type { VehicleSetup } from '../models/VehicleSetup';
import type { VehicleType } from '../models/VehicleType';
import type { VIN } from '../models/VIN';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class VehicleService {
  constructor(public readonly http: HttpClient) {}

  /**
   * List all
   * List all available vehicles
   * @returns any OK
   * @throws ApiError
   */
  public vehicleListAll(): Observable<
    Array<
      {
        group_id?: GroupIdNull;
        identities?: Array<
          UserIdentity & {
            operator_id: string | null;
            is_driver: boolean;
            activity: DtcoActivity;
          }
        > | null;
      } & VehicleInfo & {
          group_id: GroupIdNull;
          identities: Array<
            UserIdentity & {
              operator_id: string | null;
              is_driver: boolean;
              activity: DtcoActivity;
            }
          > | null;
        }
    >
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/vehicles/',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        500: `Server error`,
      },
    });
  }

  /**
   * Create
   * Create vehicle
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public vehicleCreate(
    requestBody: GroupIdObj & {
      vin?: VIN;
    } & VehicleType & {
        group_id: GroupId;
        vin: VIN;
        attr: VehicleAttr;
        icon: VehicleIcon;
      },
  ): Observable<{
    id: VehicleId;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/vehicles/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Group doesn't exist`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set trigger
   * Set trigger
   * @param vehicleId Vehicle ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public triggerSet(vehicleId: string, requestBody: Triggers): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/vehicles/{vehicle_id}/triggers',
      path: {
        vehicle_id: vehicleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Setup
   * Set vehicle setup
   *
   * `consumption_norm`, `idle_tolerance`, `fuel_loss_tolerance`, `refueling_tolerance`, `trip_duration` and `speed_limit` require less permissions in order to set
   * @param vehicleId Vehicle ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public vehicleSetup(
    vehicleId: string,
    requestBody: VehicleSetup,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/vehicles/{vehicle_id}/setup',
      path: {
        vehicle_id: vehicleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Edit service interval
   * Edit vehicle service interval
   * @param vehicleId Vehicle ID
   * @param intervalId Interval ID
   * @param requestBody
   * @returns void
   * @throws ApiError
   */
  public vehicleServiceIntervalEdit(
    vehicleId: string,
    intervalId: string,
    requestBody: IntervalName | SetOdometer | Date,
  ): Observable<void> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/vehicles/{vehicle_id}/service_interval/{interval_id}',
      path: {
        vehicle_id: vehicleId,
        interval_id: intervalId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid interval ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete service interval
   * Delete vehicle service interval
   * @param vehicleId Vehicle ID
   * @param intervalId Interval ID
   * @returns void
   * @throws ApiError
   */
  public vehicleServiceIntervalDelete(
    vehicleId: string,
    intervalId: string,
  ): Observable<void> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/vehicles/{vehicle_id}/service_interval/{interval_id}',
      path: {
        vehicle_id: vehicleId,
        interval_id: intervalId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Interval doesn't exist`,
        500: `Server error`,
      },
    });
  }

  /**
   * List service intervals
   * List vehicle service intervals
   * @param vehicleId Vehicle ID
   * @returns any OK
   * @throws ApiError
   */
  public vehicleServiceIntervalList(vehicleId: string): Observable<Array<
    IntervalId &
      IntervalName & {
        /**
         * When will it active
         */
        date?: string | null;
        /**
         * In what odometer will it active (m)
         */
        odometer?: number | null;
        /**
         * If the interval was already activated
         */
        activated?: boolean;
      } & {
        /**
         * When will it active
         */
        date: string | null;
        /**
         * In what odometer will it active (m)
         */
        odometer: number | null;
        /**
         * If the interval was already activated
         */
        activated: boolean;
      }
  >> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/vehicles/{vehicle_id}/service_interval/',
      path: {
        vehicle_id: vehicleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set service interval
   * Set vehicle service interval
   * @param vehicleId Vehicle ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public vehicleServiceInterval(
    vehicleId: string,
    requestBody: IntervalName & (SetOdometer | Date),
  ): Observable<{
    /**
     * Interval ID
     */
    id: string;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/vehicles/{vehicle_id}/service_interval/',
      path: {
        vehicle_id: vehicleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Set vehicle odometer
   * Set vehicle odometer
   * @param vehicleId Vehicle ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public vehicleOdometer(
    vehicleId: string,
    requestBody: SetOdometer & {
      /**
       * Date
       */
      date?: string;
    } & {
      /**
       * Date
       */
      date: string;
    },
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/vehicles/{vehicle_id}/odometer',
      path: {
        vehicle_id: vehicleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Upload vehicle image
   * Upload vehicle image
   * @param vehicleId Vehicle ID
   * @param formData
   * @returns any OK
   * @throws ApiError
   */
  public vehicleImageUpload(
    vehicleId: string,
    formData: {
      /**
       * JPG image max size 5MB
       */
      file: Blob;
    },
  ): Observable<{
    /**
     * Image URL
     */
    url: string;
  }> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/vehicles/{vehicle_id}/image',
      path: {
        vehicle_id: vehicleId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete vehicle image
   * Delete vehicle image
   * @param vehicleId Vehicle ID
   * @returns any Image deleted
   * @throws ApiError
   */
  public vehicleImageDelete(vehicleId: string): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/vehicles/{vehicle_id}/image',
      path: {
        vehicle_id: vehicleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Edit operator vehicle assignment
   * Edit operator vehicle assignment
   * @param vehicleId Vehicle ID
   * @param assignmentId Identity assignment ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public vehicleAssignOperatorEdit(
    vehicleId: string,
    assignmentId: string,
    requestBody:
      | {
          /**
           * Since when is the identity assigned
           */
          since: string;
        }
      | {
          /**
           * Until when is the identity assigned
           */
          until: string | null;
        },
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/vehicles/{vehicle_id}/assign_operator/assignment/{assignment_id}',
      path: {
        vehicle_id: vehicleId,
        assignment_id: assignmentId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid assignment ID`,
        409: `Conflict`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete operator vehicle assignment
   * Delete operator vehicle assignment
   * @param vehicleId Vehicle ID
   * @param assignmentId Identity assignment ID
   * @returns any Deleted
   * @throws ApiError
   */
  public vehicleAssignOperatorDelete(
    vehicleId: string,
    assignmentId: string,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/vehicles/{vehicle_id}/assign_operator/assignment/{assignment_id}',
      path: {
        vehicle_id: vehicleId,
        assignment_id: assignmentId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid identity`,
        500: `Server error`,
      },
    });
  }

  /**
   * Assign operator
   * Assign operator to vehicle, used instead of RFID/NFC/iButton login
   * @param vehicleId Vehicle ID
   * @param operatorId Operator ID
   * @param requestBody
   * @returns UserAssignmentId OK
   * @throws ApiError
   */
  public vehicleAssignOperator(
    vehicleId: string,
    operatorId: string,
    requestBody: {
      /**
       * Since when the operator is assigned to vehicle
       */
      since: string;
      /**
       * Until when will be the operator assigned to vehicle
       */
      until: string | null;
    },
  ): Observable<UserAssignmentId> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/vehicles/{vehicle_id}/assign_operator/{operator_id}/',
      path: {
        vehicle_id: vehicleId,
        operator_id: operatorId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get assigned operators
   * Get assigned operators, used instead of RFID/NFC/iButton login
   * @param vehicleId Vehicle ID
   * @returns any OK
   * @throws ApiError
   */
  public vehicleAssignOperatorList(vehicleId: string): Observable<Array<any>> {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/vehicles/{vehicle_id}/assign_operator/',
      path: {
        vehicle_id: vehicleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Detail
   * Get vehicle details
   * @param vehicleId Vehicle ID
   * @returns any OK
   * @throws ApiError
   */
  public vehicleDetail(vehicleId: string): Observable<
    VehicleInfo &
      TriggersNullable & {
        identities?: Array<
          UserIdentity & {
            operator_id: string | null;
            is_driver: boolean;
            activity: DtcoActivity;
          }
        > | null;
        location?: AddressName | null;
        /**
         * Vehicle image url
         */
        image?: string | null;
      } & {
        triggers: {
          fuel_loss?: boolean;
          refueling?: boolean;
          geofence?: boolean;
          engine?: boolean;
          country?: boolean;
          panic_alarm?: boolean;
          vehicle_activity?: boolean;
          in_1?: boolean;
          in_2?: boolean;
          in_3?: boolean;
          in_4?: boolean;
          in_5?: boolean;
          ext_in_1?: boolean;
          ext_in_2?: boolean;
          ext_in_3?: boolean;
          ext_in_4?: boolean;
          ext_in_5?: boolean;
          ignition?: boolean;
        } | null;
        identities: Array<
          UserIdentity & {
            operator_id: string | null;
            is_driver: boolean;
            activity: DtcoActivity;
          }
        > | null;
        location: AddressName | null;
        /**
         * Vehicle image url
         */
        image: string | null;
      }
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/vehicles/{vehicle_id}/?q=' + new Date().getTime(),
      path: {
        vehicle_id: vehicleId,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Edit
   * Edit vehicle
   *
   * `attr`, and `icon` require less permissions in order to set
   * @param vehicleId Vehicle ID
   * @param requestBody
   * @returns any OK
   * @throws ApiError
   */
  public vehicleEdit(
    vehicleId: string,
    requestBody?: VehicleType,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'PUT',
      url: '/vehicles/{vehicle_id}/',
      path: {
        vehicle_id: vehicleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }

  /**
   * Delete
   * Delete vehicle
   * @param vehicleId Vehicle ID
   * @param permanent
   * @returns any Vehicle deleted
   * @throws ApiError
   */
  public vehicleDelete(
    vehicleId: string,
    permanent: boolean = false,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'DELETE',
      url: '/vehicles/{vehicle_id}/',
      path: {
        vehicle_id: vehicleId,
      },
      query: {
        permanent: permanent,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Invalid vehicle ID`,
        500: `Server error`,
      },
    });
  }

  /**
   * Assign OBU to the vehicle
   * Assign OBU to the vehicle
   * @param code OBU serial number
   * @param vehicleId Vehicle ID
   * @param requestBody
   * @returns any OBU successfully assigned
   * @throws ApiError
   */
  public obuAssign(
    code: string,
    vehicleId: string,
    requestBody: ObuSinceUntil,
  ): Observable<any> {
    return __request(OpenAPI, this.http, {
      method: 'POST',
      url: '/obu/{code}/assign_vehicle/{vehicle_id}',
      path: {
        code: code,
        vehicle_id: vehicleId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Vehicle/OBU doesn't exist`,
        409: `Conflict`,
        500: `Server error`,
      },
    });
  }

  /**
   * Get latest OBU assignment
   * Get latest OBU assignment
   * @param code OBU serial number
   * @returns any Assignments
   * @throws ApiError
   */
  public obuAssignGet(code: string): Observable<
    {
      id?: AssignmentId;
      vehicle_id?: VehicleId;
      created_at?: string;
    } & ObuSince &
      ObuUntil & {
        id: AssignmentId;
        vehicle_id: VehicleId;
      }
  > {
    return __request(OpenAPI, this.http, {
      method: 'GET',
      url: '/obu/{code}/assign_vehicle/',
      path: {
        code: code,
      },
      errors: {
        400: `Validation error`,
        401: `Unauthorized`,
        403: `Not enough rights`,
        404: `Vehicle/OBU doesn't exist`,
        412: `Precondition error`,
        500: `Server error`,
      },
    });
  }
}
