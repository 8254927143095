import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { LoginService } from '../../../../api';
import {
  LocalStorageItem,
  clearStorageCache,
  getLocalStorageItem,
} from '../../../core/utils';
import { ROUTER_UTILS } from '../../../core/utils/router.utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn$ = new BehaviorSubject<boolean>(
    AuthService.getIsLoggedFromStorage(),
  );

  constructor(
    private router: Router,
    private readonly loginService: LoginService,
  ) {}

  public static getIsLoggedFromStorage() {
    try {
      return !!getLocalStorageItem(LocalStorageItem.Auth);
    } catch (e) {
      console.error(
        'Error in AuthService: getIsLoggedFromStorage. Probably changed manually token in LocalStorage',
        e,
      );
      return false;
    }
  }

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.getValue();
  }

  signIn(): void {
    this.isLoggedIn$.next(true);
  }

  async signOut(
    returnUrl: string | undefined = undefined,
    apiLogout: boolean,
  ): Promise<void> {
    if (apiLogout) {
      try {
        await lastValueFrom(this.loginService.logout());
      } catch (e) {
        console.error(e);
      }
    }
    clearStorageCache();
    this.isLoggedIn$.next(false);

    const { root, signIn } = ROUTER_UTILS.config.auth;
    this.router.navigate(['/', root, signIn], {
      queryParams: { returnUrl },
    });
  }
}
