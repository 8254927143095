import { MeDto } from '../types/MeDto';
import { LocalStorageItem, getLocalStorageItem } from './storage.utils';

export const loggedUserHasRight = (rightName: string): boolean => {
  const me = getLocalStorageItem<MeDto>(LocalStorageItem.meInformation);
  if (me) {
    return !!me.rights.find((x) => x == rightName);
  } else {
    return false;
  }
};
