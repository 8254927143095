import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../states/auth/services/auth.service';
import { loggedUserHasRight } from '../../../utils/rights.utils';
import { ROUTER_UTILS } from '../../../utils/router.utils';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() showMemu = false;

  path = ROUTER_UTILS.config.base;
  reportsPath = ROUTER_UTILS.config.reports;
  public readonly loggedUserHasRight = loggedUserHasRight;

  isCollapsed = true;

  constructor(private router: Router, private authService: AuthService) {}

  async onClickSignOut(): Promise<void> {
    await this.authService.signOut(undefined, true);
  }
}
